import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import { Container, Row, Col, Carousel } from "react-bootstrap"

const IndexPage = () => {
  return (
    <Layout>
      <Container id="intro">
        <Row className="justify-content-center">
          <Col md={12} className="text-center carousel-art d-none d-md-block">
            <Carousel controls={false} fade={true} indicators={false}>
              <Carousel.Item>
                <StaticImage
                  src="../assets/images/art-one.jpg"
                  alt="Art One"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../assets/images/art-two.jpg"
                  alt="Art Two"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col md={10}>
            <p className="description-text">
              Carla Young Fine Art Appraisals is an Accredited Member of the
              Appraisers Association of America and offers complete online
              appraisals, consultations regarding art properties, and advice
              regarding the sale or purchase of art.
            </p>
          </Col>
          <Col md={10}>
            <p className="description-buttons">
              <a
                className="btn btn-primary mx-2"
                href="/appraisals"
                role="button"
              >
                About Appraisals
              </a>
              <a className="btn btn-primary mx-2" href="/contact" role="button">
                Contact Us
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid id="specialties">
        <Container fluid className="section-grid">
          <Row className="justify-content-center">
            <Col md={12}>
              <p className="section-header">Specialties</p>
            </Col>
            <Col md={4} className="d-none d-md-block">
              <StaticImage
                src="../assets/images/american-sq.jpg"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </Col>
            <Col md={4} className="d-none d-md-block">
              <StaticImage
                src="../assets/images/european-sq.jpg"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </Col>
            <Col md={4} className="d-none d-md-block">
              <StaticImage
                src="../assets/images/latin-american-sq.jpg"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </Col>
            <Col md={10}>
              <p className="section-labels">
                American&nbsp;&nbsp;&nbsp;European&nbsp;&nbsp;&nbsp;Latin&nbsp;American
              </p>
              <p className="section-text">
                We specialize in online appraisals of paintings, drawings,
                sculpture, and prints from American, European, and Latin
                American fine arts.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid id="services">
        <Container fluid className="section-grid">
          <Row className="justify-content-center">
            <Col md={12}>
              <p className="section-header">Services</p>
            </Col>
            <Col md={6} className="d-none d-md-block">
              <StaticImage
                src="../assets/images/laptop-sq.jpg"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </Col>
            <Col md={6} className="d-none d-md-block">
              <StaticImage
                src="../assets/images/frames-sq.jpg"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </Col>
            <Col md={6}>
              <p className="section-labels">Online Appraisals</p>
              <p className="section-text-small">
                We provide online appraisals for insurance, and opinion of value
                reports for the edification of the owner of the artwork.
              </p>
            </Col>
            <Col md={6}>
              <p className="section-labels">Consultation</p>
              <p className="section-text-small">
                Our services include advice and recommendations regarding
                appropriate venues for sale, as well as valuation of artwork.
              </p>
            </Col>
            <Col md={12}>
              <p className="description-buttons">
                <a
                  className="btn btn-primary mx-2"
                  href="/appraisals"
                  role="button"
                >
                  Learn More About Appraisals
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default IndexPage
